import React, { Component } from "react"

import ReactModal from "react-modal"
import { Link } from "gatsby"

import JotFormReact from "jotform-react"
import { StaticImage } from "gatsby-plugin-image"

ReactModal.setAppElement("#___gatsby")

class ModalSouthlake extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
    }
  }
  handleModalOpen = event => {
    console.log("handleModalOpen: ", event)
    this.setState({ isModalOpen: true })
  }

  handleModalClose = event => {
    console.log("handleModalOpen: ", event)
    this.setState({ isModalOpen: false })
  }

  handleSubmit = () => {
    console.log("handleSubmit: ")
  }

  render() {
    return (
      <div>
        <div id="modalmain">
          <Link
            to="#"
            className="btn btn-nowrap"
            onClick={this.handleModalOpen}
          >
            Contact Southlake
          </Link>
        </div>
        <ReactModal
          isOpen={this.state.isModalOpen}
          onRequestClose={this.handleModalClose}
          contentLabel="Content Modal"
          style={{
            overlay: {},
            content: {
              padding: "0px",
            },
          }}
        >
          <div className="p-5 px-0 text-center">
            <StaticImage
              src="../../images/Logos/Southlake.png"
              loading="eager"
              width={320}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt=""
              className=""
            />
            <h2 className="text-center pt-5">Contact Our Southlake Location</h2>

            <JotFormReact
              formURL="https://hipaa.jotform.com/222163263929155"
              formID="222163263929155"
              onSubmit={this.handleSubmit}
            />

            <h4 className="text-uppercase my-4">Southlake Location</h4>
            <p>
              <a href="tel:8174272622">P: 817-427-2622</a> <br />
              777 Bandit Trail Unit A Keller, TX 76248
            </p>

            <div className="cft-footer__ifw">
              <iframe
                title="Google Map"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=777%20Bandit%20Trail%20Unit%20A%20Keller,%20TX%2076248+(Care%20Free%20Teeth)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div>

            <button className="modal-closer" onClick={this.handleModalClose}>
              <div>x</div>
            </button>
          </div>
        </ReactModal>
      </div>
    )
  }
}

export default ModalSouthlake
