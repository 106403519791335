import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ModalSouthlake from "./modalsouthlake"
import ModalMansfield from "./modalmansfield"

const ContactSection = () => (
  <div className="cft-contact" id="contactus">
    <div className="cft-about__inner py-3 py-md-5">
      <div className="cft-test__inner text-center pt-3 pt-md-5">
        <h2 className="h2 text-center fw-bold text-uppercase">Get in Touch</h2>
      </div>
      <div className="container">
        <div className="row p-2 lg:p-5">
          <div className="col-md-6 text-center">
            <StaticImage
              src="../../images/Logos/mansfield-logo.png"
              loading="eager"
              width={320}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt=""
              className=""
            />
            <div className="py-md-4"></div>
          </div>
          <div className="col-md-6 text-center pt-4">
            <ModalMansfield />
          </div>

          <div className="pt-5 pt-md-0 col-md-6 text-center">
            <StaticImage
              src="../../images/Logos/Southlake.png"
              loading="eager"
              width={320}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt=""
              className=""
            />
            <div className="py-md-4"></div>
          </div>
          <div className="col-md-6 text-center pt-2 pb-4 pb-md-0 pt-md-5">
            <ModalSouthlake />
          </div>
        </div>
      </div>
      <div className="text-center">
        <strong className="fs-3"> Give us a call: </strong>
        <br />
        <a className="btn" href="tel:817-427-2622">
          817-427-2622
        </a>
      </div>
    </div>
  </div>
)

export default ContactSection
