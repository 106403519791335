import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"


const Benefits2 = () => (

<div className="cft-why__wrapper">
    <div className="cft-hero cft-why cft-section background-blue">
        <StaticImage
            src="../../images/photo-1489278353717-f64c6ee8a4d2.jpg"
            loading="eager"
            width={1440}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
            className="cft-hero__bg cft-section__background"
            style={{
            }}
        />

        <div className="cft-hero__copy cft-why__hero mx-auto mb-3 color-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="h1 fw-bold color-white">
                                What sets us apart
                            </h2>
                            <h3>Premier Implant Center</h3>

                        </div>
                    </div>
                </div>
            </div>

    </div>

    <div className="cft-why__copy mx-auto">
        <div className="container">
            <div className="row">

                <div className="col-11 col-md-10 mx-auto">

                    <div className="my-5">
                        <h3 className="h3--small">Experience</h3>
                        <p>Doctors Amir Koujan and Stephen Mcananey have been providing comprehensive advanced dental care for a combined 50 years.</p>
                    </div>
                    <div className="my-5">
                    <h3 className="h3--small">Sedation</h3>
                        <p>Sedation, and Anesthesia solutions to keep you calm and comfortable.  We offer full sedation and anesthesiology services that help you wake up with a new carefree smile.</p>
                    </div>
                    <div className="my-5">
                        <h3 className="h3--small">Implants</h3>
                        <p>The long-term success rates of implants are impressive. Implant dentistry replaces your missing teeth to fully restore your smile. </p>
                    </div>
                    <div className="my-5">
                        <h3 className="h3--small">Savings</h3>
                        <p>We save our patients time and money by providing all dental services in one location under one roof.  Dental implants are the new standard in dental care that will help you live a carefree life. Stop wasting money on other dentistry options that don't last.</p>
                    </div>
                </div>

                <div className="text-center p-3">
                    <a className="btn" href="#testimonials">What people have to say</a>
                </div>


                {/* <div className="col-md-6 col-lg-3">
                    <div className="cft-why__inner m-2 background-white color-white p-2 p-lg-4 cft-section">
                        <StaticImage
                            src="../../images/photo-1573294705900-9623cfc746b7.jpg" style={{ opacity: `0.66` }} loading="eager" width={512} quality={95} formats={["auto", "webp", "avif"]} alt="" className="cft-section__background cft-romance__soft"
                        />
                        <h3 className="h3--small">Experience</h3>
                        <div className="cft-why__spacer"></div>
                        <p>Dr. Stephen McAnaney, DDS has been providing comprehensive, leading edge dental care for over 27 years in dentistry.</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="cft-why__inner m-2 background-white color-white p-2 p-lg-4 cft-section">
                        <StaticImage
                            src="../../images/photo-1567516364473-233c4b6fcfbe.jpg" style={{ opacity: `0.66` }} loading="eager" width={512} quality={95} formats={["auto", "webp", "avif"]} alt="" className="cft-section__background cft-romance__soft"
                        />
                        <h3 className="h3--small">Sedation</h3>
                        <div className="cft-why__spacer"></div>
                        <p>In keeping with our interest to deliver the best possible experiences to our patients, we also offer several sedation dentistry options.</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="cft-why__inner m-2 background-white color-white p-2 p-lg-4 cft-section">
                        <StaticImage
                            src="../../images/photo-1607378119679-1b10e82b3704.jpg" style={{ opacity: `0.66` }} loading="eager" width={512} quality={95} formats={["auto", "webp", "avif"]} alt="" className="cft-section__background cft-romance__soft"
                        />
                        <h3 className="h3--small">Implants</h3>
                        <div className="cft-why__spacer"></div>
                        <p>The long-term success rates of implants are impressive. Implant dentistry replaces your missing teeth to fully restore your smile. </p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="cft-why__inner m-2 background-white color-white p-2 p-lg-4 cft-section">
                        <StaticImage
                            src="../../images/photo-1595152772835-219674b2a8a6.jpg" style={{ opacity: `0.66` }} loading="eager" width={512} quality={95} formats={["auto", "webp", "avif"]} alt="" className="cft-section__background cft-romance__soft"
                        />
                        <h3 className="h3--small">Savings</h3>
                        <div className="cft-why__spacer"></div>
                        <p>Everyone wants to receive the highest quality dental services, affordably. Our patients get the quality dental care they need.</p>
                    </div>
                </div> */}

            </div>
        </div>
    </div>
</div>
)


export default Benefits2
