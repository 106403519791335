import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { StaticImage } from "gatsby-plugin-image"

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";


// import required modules
import { Pagination } from "swiper";

const BaSlider = () => (

    <div className="cft-slider1 background-white py-3 py-md-5 box-shadow">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
            320: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
                slidesPerView: 4,
            },
          }}
        modules={[Pagination]}
        className="mySwiper"
      >
{/*         <SwiperSlide>
            <StaticImage src="../../images/ba-photos/1.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/2.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/3.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/4.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/5.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/6.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/7.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/8.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/9.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide> */}
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/1-resize.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/6-resize.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/41-resize.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/10-resize.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/11-resize.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/12-resize.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/13-resize.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/14-resize.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/15-resize.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/16-resize.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/17-resize.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/18-resize.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/19-resize.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/30-resize.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        {/* <SwiperSlide>
            <StaticImage src="../../images/ba-photos/13.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/14.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/15.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/16.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/17.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/18.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/19.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/20.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/21.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/22.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/23.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/24.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/25.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/26.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/27.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/28.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/29.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/30.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/31.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/32.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/33.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/34.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/35.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/36.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/37.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/38.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/39.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide>
        <SwiperSlide>
            <StaticImage src="../../images/ba-photos/40.jpg" loading="eager" width={320} quality={85} formats={["auto", "webp", "avif"]} alt="" className="not-cft-section__background" />
        </SwiperSlide> */}

      </Swiper>
    </div>
  );

export default BaSlider

