import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Certifications = () => (
    <div className="cft-certifications  p-3 p-md-5 cft-section">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <h4 className="text-uppercase mb-4">Certification</h4>
                    <div className="cft-footer__logos background-white">
                        <StaticImage src="../../images/Logos/AAD+McAnaney.png" loading="eager" width={500} quality={95} formats={["auto", "webp", "avif"]} alt="" className="cft-footer__logos-single" />
                        <StaticImage src="../../images/Logos/AAFE+McAnaney.png" loading="eager" width={500} quality={95} formats={["auto", "webp", "avif"]} alt="" className="cft-footer__logos-single" />
                        <StaticImage src="../../images/Logos/AAID+McAnaney.png" loading="eager" width={500} quality={95} formats={["auto", "webp", "avif"]} alt="" className="cft-footer__logos-single" />
                        <StaticImage src="../../images/Logos/ADA+McAnaney.png" loading="eager" width={500} quality={95} formats={["auto", "webp", "avif"]} alt="" className="cft-footer__logos-single" />
                        <StaticImage src="../../images/Logos/Crown+Council+McAnaney.png" loading="eager" width={500} quality={95} formats={["auto", "webp", "avif"]} alt="" className="cft-footer__logos-single" />
                        <StaticImage src="../../images/Logos/DOCS+McAnaney.png" loading="eager" width={500} quality={95} formats={["auto", "webp", "avif"]} alt="" className="cft-footer__logos-single" />
                        <StaticImage src="../../images/Logos/ICOI+McAnaney.png" loading="eager" width={500} quality={95} formats={["auto", "webp", "avif"]} alt="" className="cft-footer__logos-single" />
                        <StaticImage src="../../images/Logos/Insititute+McAnaney.png" loading="eager" width={500} quality={95} formats={["auto", "webp", "avif"]} alt="" className="cft-footer__logos-single" />
                        <StaticImage src="../../images/Logos/TDA+McAnaney.png" loading="eager" width={500} quality={95} formats={["auto", "webp", "avif"]} alt="" className="cft-footer__logos-single" />
                        <StaticImage src="../../images/Logos/TPC+McAnaney.png" loading="eager" width={500} quality={95} formats={["auto", "webp", "avif"]} alt="" className="cft-footer__logos-single" />
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default Certifications