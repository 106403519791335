import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"


const Footer = () => (
<div className="cft-footer background-dark color-white p-3 p-md-5 cft-section">
    <StaticImage
        src="../../images/photo-1521133573892-e44906baee46.jpg"
        loading="eager"
        width={20}
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt=""
        className="cft-section__background cft-romance__soft"
        style={{
            opacity: '0.4'
        }}
    />

    <div className="container">
        <div className="row">
            <div className="col-md-6">
                <h4 className="text-uppercase mb-4">Contact Mansfield</h4>
                <div className="footer__contact">
                    <p>
                        <a href="tel:8174052245">P: 817-405-2245</a> <br />
                        1601 E Debbie Ln, Ste 1125 Mansfield, TX 76063
                    </p>

                    <ul className="ul--indent">
                        <li><strong>Monday:</strong> 9am to 5pm</li>
                        <li><strong>Tuesday:</strong> 9am to 5pm</li>
                        <li><strong>Wednesday:</strong> 9am to 5pm</li>
                        <li><strong>Thursday:</strong> 9am to 5pm</li>
                        <li><strong>Friday, Saturday, & Sunday:</strong> Closed</li>
                    </ul>
                    
                    <br />
                    
                    <h4 className="text-uppercase mb-4">Contact Southlake</h4>
                    <p>
                        <a href="tel:8174272622">P: 817-427-2622</a> <br />
                        777 Bandit Trail Unit A Keller, TX 76248
                    </p>

                    <ul className="ul--indent">
                        <li><strong>Monday:</strong>  9am to 5pm</li>
                        <li><strong>Tuesday:</strong> 9am to 5pm</li>
                        <li><strong>Wednesday:</strong> 9am to 5pm</li>
                        <li><strong>Thursday:</strong>  9am to 5pm</li>
                        <li><strong>Friday, Saturday, & Sunday:</strong> Closed</li>
                    </ul>
                </div>
            </div>
            <div className="col-md-6">

                <h4 className="text-uppercase my-4">Mansfield Location</h4>
                <div className="cft-footer__ifw">
                    <iframe title="Google Map" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1601%20E%20Debbie%20Ln,%20Ste%201125%20Mansfield,%20TX%2076063+(CareFreeTeeth)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                </div>

                <h4 className="text-uppercase my-4">Southlake Location</h4>
                <div className="cft-footer__ifw">
                    <iframe title="Google Map" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=777%20Bandit%20Trail%20Unit%20A%20Keller,%20TX%2076248+(Care%20Free%20Teeth)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                </div>


            </div>
        </div>
    </div>
</div>
)


export default Footer
