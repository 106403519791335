import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const BeforeAfter = () => (
  <div className="cft-hero cft-ba background-blue cft-section color-white">
    <StaticImage
      src="../../images/ba-photos/37.jpg"
      loading="eager"
      width={5}
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt=""
      className="cft-section__background cft-romance__soft"
      style={{
        opacity: "0.4",
        filter: "blur(15px)",
      }}
    />

    <div className="cft-hero__copy cft-ba__copy mx-auto mb-3 mt-3 color-white">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 p-l-md-5">
            <h3 className="h2 text-uppercase fw-bold color-white">
              Creating Life Changing, Healthy, Confident, Carefree Smiles.
            </h3>
            {/* <h4>Creating Life Changing, Healthy, Confident, Carefree Smiles.</h4> */}
            {/* <br /> */}
            {/* <p>Dr. Stephen McAnaney has been providing gentle, patient-centered care for more than 20 years. He offers the very best treatment available in the United States, due to his extensive training. Dr. McAnaney is committed to offering advanced dental procedures and using leading technology.</p> */}
          </div>
          <div className="col-lg-6 cft-ba__vwrapper">
            <div className="cft-ba__frame">
              <iframe
                title="Before and After Video"
                className="cft-ba__video"
                src="https://drive.google.com/file/d/1PHywufVw_5EJqLt-8fMMBD5qOEkfpb0v/preview"
                allowFullScreen={true}
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row"></div>
      </div>
    </div>
  </div>
)

export default BeforeAfter
