import * as React from "react"
// import { StaticImage } from "gatsby-plugin-image"
// import VideoSlider from "./videoslider"
import TestSlider from "./testslider"


const Testimonials = () => (
<>
    <div className="cft-test p-0 p-md-5 box-shadow background-gray" id="testimonials">
        <div className="container">
            <div className="row">

                <div className="cft-test__inner text-center">
                    <h2 className="h1 text-center fw-bold text-uppercase">Testimonials</h2>
                    <h3>What our satisfied patients have to say</h3>
                </div>

                <TestSlider />
            </div>
        </div>

    </div>

    {/* Removed—Out of scope */}

</>
)


export default Testimonials
