import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/blocks/hero"
import Benefits from "../components/blocks/benefits"
import Benefits2 from "../components/blocks/benefits2"
import BeforeAfter from "../components/blocks/beforeafter"
import BaSlider from "../components/blocks/baslider"
import Testimonials from "../components/blocks/testimonials"
import AboutUs from "../components/blocks/about"
import Footer from "../components/blocks/footer"
import Certifications from "../components/blocks/certifications"
// import VideoSlider from "../components/blocks/videoslider"


const IndexPage = () => (
    <Layout>
        <Seo title="CareFreeTeeth: Southlake/Mansfield" />
        <div>

            <Hero />

            {/* <VideoSlider /> */}

            <BaSlider />

            <Testimonials />

            <Benefits />

            <Benefits2 />

            <BeforeAfter />

            <AboutUs />

            <Footer />

            <Certifications />
        </div>
    </Layout>
)

export default IndexPage
