import React, { Component } from 'react'

import ReactModal from 'react-modal'
import { Link } from 'gatsby'

import JotFormReact from 'jotform-react'
import { StaticImage } from "gatsby-plugin-image"

ReactModal.setAppElement('#___gatsby')

class ModalHero extends Component {
    constructor(props) {
        super(props)
        this.state = {
        isModalOpen: false,
        }
    }
    handleModalOpen = event => {
        console.log('handleModalOpen: ', event);
        this.setState({ isModalOpen: true })
    }

    handleModalClose = event => {
        console.log('handleModalOpen: ', event);
        this.setState({ isModalOpen: false })
    }

    render() {
        return (
            <div>
                <div id="modalmain">
                    <Link to="#" className="btn btn-nowrap hero-modal-opener" onClick={this.handleModalOpen}>
                        Play Video
                    </Link>
                </div>
                <ReactModal
                    isOpen={this.state.isModalOpen}
                    onRequestClose={this.handleModalClose}
                    contentLabel="Content Modal"
                    style={
                        { overlay: {},
                        content: {
                            padding: '0px'
                        } }
                    }
                >
                    <div className="p-5 px-0 text-center">
                        <iframe 
                            className="modal-video"
                            src="https://www.youtube.com/embed/U-SOIkFNl7M?controls=0&autoplay=1" 
                            title="YouTube video player" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            allowfullscreen
                        ></iframe>

                        <button className="modal-closer" onClick={this.handleModalClose}>
                            <div>x</div>
                        </button>
                    </div>
                </ReactModal>
            </div>
        )
    }
}

export default ModalHero