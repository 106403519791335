import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ModalSouthlake from "./modalsouthlake"
import ModalMansfield from "./modalmansfield"
import ModalHero from "./modalhero"


const Hero = () => (
<>
    <div className="cft-hero cft-hero--main">
        <StaticImage
            src="https://img.youtube.com/vi/U-SOIkFNl7M/maxresdefault.jpg"
            loading="eager"
            width={1440}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
            className="cft-hero__bg"
        />
        <ModalHero />

        <div className="cft-hero__copy mx-auto mb-3">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 not-mx-auto">
                        {/* <h1 className="mb-2 mb-md-3 px-md-3">
                            Experienced Dental Implant Doctors Creating Carefree Smiles for 1,000<span className="lowercase">s</span> of patients.
                        </h1> */}
                        <div className="h5 mb-2 mb-md-5 px-md-3">
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="cft-hero__testimonal">
        <StaticImage
            src="../../images/bcs1.png"
            loading="eager"
            width={1440}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
            className="cft-hero--insert2"
        />
        <br />
        <br />
        <h3 className="text-center">Call Today to Use Your BlueCross BlueShield of Texas Medical Coverage</h3>
        <a className="btn btn--secondary" href="tel:8174272622">817-427-2622</a>
    </div>
</>
)


export default Hero
