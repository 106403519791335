import React, { Component } from "react"

import ReactModal from "react-modal"
import { Link } from "gatsby"

import JotFormReact from "jotform-react"
import { StaticImage } from "gatsby-plugin-image"

ReactModal.setAppElement("#___gatsby")

class ModalMansfield extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
    }
  }
  handleModalOpen = event => {
    console.log("handleModalOpen: ", event)
    this.setState({ isModalOpen: true })
  }

  handleModalClose = event => {
    console.log("handleModalOpen: ", event)
    this.setState({ isModalOpen: false })
  }

  handleSubmit = () => {
    console.log("handleSubmit: ")
  }

  render() {
    return (
      <div style={{ zIndex: 9999 }}>
        <div id="modalmain">
          <Link
            to="#"
            className="btn btn-nowrap"
            onClick={this.handleModalOpen}
          >
            Contact Mansfield
          </Link>
        </div>
        <ReactModal
          isOpen={this.state.isModalOpen}
          onRequestClose={this.handleModalClose}
          contentLabel="Content Modal"
          style={{
            overlay: {},
            content: {
              padding: "0px",
            },
          }}
        >
          <div className="p-5 px-0 text-center">
            <StaticImage
              src="../../images/Logos/mansfield-logo.png"
              loading="eager"
              width={320}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt=""
              className=""
            />
            <h2 className="text-center pt-5">Contact Our Mansfield Location</h2>

            <JotFormReact
              formURL="https://hipaa.jotform.com/222162567783058"
              formID="222162567783058"
              onSubmit={this.handleSubmit}
            />

            <h4 className="text-uppercase my-4">Mansfield Location</h4>
            <p>
              <a href="tel:8174052245">P: 817-405-2245</a> <br />
              1601 E Debbie Ln, Ste 1125 Mansfield, TX 76063
            </p>
            <div className="cft-footer__ifw">
              <iframe
                title="Google Map"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1601%20E%20Debbie%20Ln,%20Ste%201125%20Mansfield,%20TX%2076063+(CareFreeTeeth)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div>

            <button className="modal-closer" onClick={this.handleModalClose}>
              <div>x</div>
            </button>
          </div>
        </ReactModal>
      </div>
    )
  }
}

export default ModalMansfield
