import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import ContactSection from "./contact"


const AboutUs = () => (
<>
    <div className="cft-about cft-section">
        <div className="cft-about__bg cft-section__background">
            <StaticImage
                src="../../images/photo-1637625854255-d893202554f4.jpg"
                loading="eager"
                width={920}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt=""
                className="cft-section__background"
            />
        </div>

        <ContactSection />

        <div className="cft-about__inner py-3 py-md-5">


            <div className="cft-test__inner text-center">
                <h2 className="h2 text-center fw-bold text-uppercase">Meet the Doctors</h2>
            </div>

            <div className="container">

            <div className="row align-items-center py-3 py-md-5">
                    <div className="col-md-3 background-ice cft-about__left cft-section box-shadow">
                        <StaticImage
                            src="../../images/drk.png"
                            loading="eager"
                            width={920}
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt=""
                            className="cft-section__background"
                        />
                    </div>

                    <div className="col-md-9 background-dark color-white cft-about__right cft-about__right--2 p-2 p-md-5 cft-section">
                        <StaticImage
                            src="../../images/drk.png"
                            loading="eager"
                            width={5}
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt=""
                            className="cft-section__background cft-romance__soft"
                            style={{
                                opacity: '0.4',
                                filter: 'blur(20px)',
                            }}
                        />

                        <h3 className="text-uppercase mb-4">Dr. Amir Koujan, DDS</h3>
                        <p>
                            As a graduate of the Advanced Education in Prosthodontics program at the University of Alabama at Birmingham School of Dentistry Dr. Koujan is committed to helping patients and changing lives through prosthodontics care. Having achieved the highly coveted UAB Prosthodontics Award and with over 20 years of dental experience Dr. Koujan has helped countless patients achieve the smiles they have always wanted.
                        </p>
                        <p>
                            When he is not treating patients, he is continuously researching and experimenting in the lab to find better ways to help his patients and improve the dental community. He enjoys spending time with his family and traveling and his happy to call Mansfield, TX his home.
                        </p>
                    </div>

                </div>

                <div className="row align-items-center py-3 py-md-5">

                    <div className="col-md-3 background-ice cft-about__left cft-section box-shadow">
                        <StaticImage
                            src="../../images/dr-mac-headshot-2022.jpg"
                            loading="eager"
                            width={920}
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt=""
                            className="cft-section__background"
                        />
                    </div>

                    <div className="col-md-9 background-dark color-white cft-about__right p-2 p-md-5 cft-section">
                        <StaticImage
                            src="../../images/dr-mac-headshot-2022.jpg"
                            loading="eager"
                            width={5}
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt=""
                            className="cft-section__background cft-romance__soft"
                            style={{
                                opacity: '0.4',
                                filter: 'blur(20px)',
                            }}
                        />

                        <h3 className="text-uppercase mb-4">DR STEPHEN MCANANEY, DDS</h3>
                        <p>
                            Dr. McAnaney has been providing comprehensive, leading edge dental care for over 30 years. Dr. McAnaney understands the importance of a healthy mouth in its relationship to the body's overall health. Because of this he is always persistently merging dental wellness with overall wellness in an effort not only to prevent dental disease but detrimental diseases associated with the entire body.
                        </p>
                        <p>
                            Dr. McAnaney is a true believer in thorough, personalized patient care and personal service. He is strongly committed to advanced modern dental treatments, and a devotion to learning and applying modern day technologies that allow patients to receive an unprecedented optimal result from their treatment experience.
                        </p>
                        <p>
                            Dr. McAnaney and his team are consistently recognized in the top percent of all Dentist's in the United States, and Canada. They strive to offer a true Five Star experience with excellent superior results.
                        </p>
                        <p>
                            Dr. McAnaney achieved his undergraduate degree at the prestigious Austin College located in Sherman, Texas. Dr. McAnaney achieved his Doctorate degree in dental care at the top rated University of Texas Health Science Center in San Antonio, Texas Dr. McAnaney is a graduate of the Georgia College of Medicine I.V. Sedation anesthesiology - post graduate program
                        </p>
                        <p>
                            Dr. McAnaney enjoys boating and riding wave runners, hiking and wildlife viewing, shooting sports/sporting clays, traveling, working at his ranch, and most of all spending time with his wife Felicia.
                        </p>
                    </div>

                </div>


                

                {/* <div className="row align-items-center my-3 my-md-5 cft-section cft-about__teamphoto box-shadow">
                    <StaticImage
                        src="../../images/team.png"
                        loading="eager"
                        width={1440}
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt=""
                        className="cft-section__background"
                    />
                </div>

                <div className="row my-3 my-md-5 cft-about__teamcopy justify-content-center">
                    <div className="col-md-10">
                        <h2 className="text-center h2 fw-bold text-uppercase">Our Team</h2>
                        <p>
                            At Mansfield Dental Center, our team of dedicated dental professionals share one goal, to provide the best dental care to the greater Mansfield community. From the moment you schedule your first appointment, to your arrival and treatment, you will be treated with courtesy and respect. We want to be your family dentist for years to come, and we’ll work hard to earn your trust.
                        </p>
                        <p>
                            Here in our Mansfield dentist office, you are an active partner in your treatment. We will help you navigate any number of options to find the best solutions for you and your family.
                        </p>
                        <p>
                            Our team of doctors, hygienists, assistants, and staff is proud to serve the people of Mansfield with all of their dental needs. We look forward to working with you to protect your smile.
                        </p>
                    </div>
                </div> */}

                <div className="text-center p-3">
                    <a className="btn" href="#contactus">Get in Touch with us</a>
                </div>

            </div>
        </div>

    </div>
</>
)


export default AboutUs
