import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"


const Benefits = () => (

<div className="cft-section cft-romance">

    <StaticImage
            src="../../images/photo-1558591710-4b4a1ae0f04d.jpg"
            loading="eager"
            width={920}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
            className="cft-section__background cft-romance__bg"
        />

    <div className="cft-split">
        <div className="cft-split__copy mx-auto mb-3">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-8 background-white color-dark p-4 p-md-5 cft-split__left cft-section box-shadow">

                        <StaticImage
                            src="../../images/photo-1521133573892-e44906baee46.jpg"
                            loading="eager"
                            width={20}
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt=""
                            className="cft-section__background cft-romance__soft"
                            style={{
                                opacity: '0.4'
                            }}
                        />

                        <h2>Benefits of Dental Implants</h2>
                        <p>
                            Dental implants offer many advantages that help patients regain dental function, restore appearance, and enjoy long-term value.
                        </p>
                        <ul className="not-ul--special">
                            <li>
                                <strong>Lifetime Solution:</strong> Dental implants can last a lifetime with proper care. Short-term fixes like bridges and dentures typically only last 5-10 years, and these recurring costs can add up.
                            </li>

                            <li>
                                <strong>Like Real Teeth:</strong> Dental implants look, feel, and work like natural teeth. You can eat comfortably, speak without them moving around in your mouth, and avoid the hassles of daily removal.
                            </li>

                            <li>
                                <strong>Prevent Bone Loss</strong> The jawbone begins to deteriorate after teeth are lost, which over time creates a sunken-in look that can make you appear older. Dental implants help preserve the jawbone.
                            </li>
                        </ul>

                        <div className="cft-split__spacer"></div>

                        <a href="#contactus" className="btn btn--secondary">Contact Us</a>


                    </div>
                    <div
                        className="col-10 col-md-4 background-blue color-white p-4 cft-split__right"
                        style={{
                            maxHeight: `auto`,
                            height: `auto`
                        }}
                    >
                        <StaticImage
                            src="../../images/FullSizeRender.jpg"
                            loading="eager"
                            width={1440}
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt=""
                            className="cft-split__img"
                        />
                        <div className="cft-testslide">
                            <p className="p-3 fs-2">“Dental Implants<br/> changed my life.”</p>
                            <div className="cft-split__quote">
                                <strong>- ‘Dan the Weatherman’ Brounoff</strong><br />
                                &nbsp;&nbsp;NewsRadio 1080,
                                Chief Meteorologist
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="cft-benefits">
        <div className="container">
            <div className="row">
                <div className="col-10 col-sm-11 col-md-10 mx-auto">

                    <div className="container box-shadow">
                        {/* <div className="row">
                            <div className="col-md-4 background-white color-white cft-section">
                                <StaticImage
                                    src="../../images/ba-photos/6-feature.jpg"
                                    loading="eager"
                                    width={920}
                                    quality={95}
                                    formats={["auto", "webp", "avif"]}
                                    alt=""
                                    className="cft-section__background"
                                    style={{
                                        // opacity: '1'
                                    }}
                                />
                            </div>
                            <div className="col-md-8 background-blue color-white p-3 py-4 p-md-5">
                                <h3>Dental Implant Cost Guide</h3>
                                <p>
                                    From the actual surgical requirements to your individual smile design options, there are many factors that influence cost.
                                </p>

                                <ul className="ul--indent">
                                    <li>
                                        <strong>How many</strong> missing or damaged teeth need to be replaced
                                    </li>
                                    <li>
                                        <strong>What your treatment goals</strong> and needs are
                                    </li>
                                    <li>
                                        <strong>The materials</strong> used in your dental restoration
                                    </li>
                                    <li>
                                        <strong>Surgical requirements</strong> and the steps needed in your treatment
                                    </li>
                                </ul>

                            </div>
                        </div> */}
                    </div>


                    <div className="container mt-3 mt-md-5 box-shadow">
                        <div className="row">
                            <div className="col-md-4 background-white color-white cft-section">
                                <StaticImage
                                    src="../../images/ba-photos/1-feature.jpg"
                                    loading="eager"
                                    width={920}
                                    quality={95}
                                    formats={["auto", "webp", "avif"]}
                                    alt=""
                                    className="cft-section__background"
                                    style={{
                                        // opacity: '1'
                                    }}
                                />
                            </div>
                            <div className="col-md-8 background-blue color-white p-3 py-4 p-md-5">
                                <h3>Top Dental Implant Center</h3>
                                <p>
                                    When you choose Dr. Stephen McAnaney and Dr. Amir Koujan, you can expect a comprehensive approach from our doctors, offering you plenty of affordable options and working through them based on your goals and needs. Plus:
                                </p>

                                <ul className="ul--indent">
                                    <li>
                                        <strong>Cosmetic and Smile Makeover</strong> cases completed in just one visit!
                                    </li>
                                    <li>
                                        <strong>Using Dental Implants</strong> and Permanently secured teeth we can replace an entire smile
                                    </li>
                                    <li>
                                        <strong>Easy Financing</strong> and All-inclusive case fees quoted - No Surprises!
                                        The materials used in your dental restoration
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>


</div>

)


export default Benefits
