import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";



export default function TestSlider() {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (


    <div className="cft-testslider">
            <Swiper
                style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
                }}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2"
                
            >
                <SwiperSlide>
                    <div className="cft-testslide">
                        <p>
                            First class experience... on time, friendly, professional and painless... a lot of pluses for an implant for a broken tooth. If you need any dental work done I could not recommend Dr. Mcananey and his staff more. They are the best in Texas!!
                        </p>
                        <div className="cft-testslide__by">
                            <div className="cft-testslide__name">
                                — Jim M.
                            </div>
                            <div className="cft-testslide__stars">★★★★★</div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="cft-testslide">
                        <p>
                            I was never a shy person...but didn't like my picture taken because of my teeth. The staff and Dr McAnaney  have changed that for me!! Not only do I LOVE MY SMILE, I pose for pictures! The whole office works as a team family, and they make you feel like you are part of it!
                        </p>
                        <div className="cft-testslide__by">
                            <div className="cft-testslide__name">
                                — Chelsie W.
                            </div>
                            <div className="cft-testslide__stars">★★★★★</div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="cft-testslide">
                        <p>
                            I never knew a Dental office experience could be so fun and welcoming! The staff has always been great to me and all other patients that I have observed. Dr McAnaney is amazing at what he does, and patients always tell me how they enjoy seeing him. I couldn’t be more excited to be a part of this dental team and family. Send your friends and family to receive the same warming experience!
                        </p>
                        <div className="cft-testslide__by">
                            <div className="cft-testslide__name">
                                — Janet S.
                            </div>
                            <div className="cft-testslide__stars">★★★★★</div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="cft-testslide">
                        <p>
                            This was my first visit to this dental office. I have had my Dentist in Dallas for over 15 years. Since moving to Pottsboro I finally decided to check out Dentist. I am a very meticulous person when it comes to dentistry. I only had my teeth cleaned at this first visit. I was very happy with the hygienists. Very thorough. Then before I left Dr McAnaney came in to visit. I was very impressed with this practice. I will return.
                        </p>
                        <div className="cft-testslide__by">
                            <div className="cft-testslide__name">
                                — Debi K.
                            </div>
                            <div className="cft-testslide__stars">★★★★★</div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="cft-testslide">
                        <p>
                            Best in the West! Make that entire country! My previous dentist had retired. I knew I needed extensive reconstruction/and dentures and searched high and low for a practice such as this. I found Dr. McAnaney early one morning on TV! I knew by listening and seeing his program this was the place for me! I've had dental problems my entire life and knew by seeing reviews from his other patients this was the place for me and it is! I love everything about this practice! They explain honestly everything to you and do everything in their power for you to have that new smile and self confidence. They will take excellent care of you, I promise!
                        </p>
                        <div className="cft-testslide__by">
                            <div className="cft-testslide__name">
                                — Charlotte
                            </div>
                            <div className="cft-testslide__stars">★★★★★</div>
                        </div>
                    </div>
                </SwiperSlide>

            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={1}
                centeredSlides={true}
                loop={true}
                freeMode={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper cft-thumb__swiper"
                breakpoints={{
                    320: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    1024: {
                        slidesPerView: 4,
                    },
                  }}
            >
                <SwiperSlide>
                    <div className="cft-thumb">
                        <div className="cft-thumb__name">
                            Jim M.
                        </div>
                        <div className="cft-testslide__stars">★★★★★</div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="cft-thumb">
                        <div className="cft-thumb__name">
                            Chelsie W.
                        </div>
                        <div className="cft-testslide__stars">★★★★★</div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="cft-thumb">
                        <div className="cft-thumb__name">
                            Janet S.
                        </div>
                        <div className="cft-testslide__stars">★★★★★</div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="cft-thumb">
                        <div className="cft-thumb__name">
                            Charlotte
                        </div>
                        <div className="cft-testslide__stars">★★★★★</div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="cft-thumb">
                        <div className="cft-thumb__name">
                            Debi K.
                        </div>
                        <div className="cft-testslide__stars">★★★★★</div>
                    </div>
                </SwiperSlide>

            </Swiper>
        </div>
    );
}
